import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { useEffect, useState } from "react";

import altImage from "~/assets/images/fallback/main-image-fallback.png";
import { cn } from "~/utils/classnames";
import { tailwindTheme } from "~/utils/theme";

interface BaseImageProps extends Omit<ComponentPropsWithoutRef<"img">, "src"> {
  src?: string | null | undefined;
  alt: string;
  responsive?: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
    "2xl"?: string;
  };
  pictureClassName?: string;
}

type Fallback = { fallbackSrc?: string } | { fallbackElement?: ReactNode };

type ImageProps = BaseImageProps & Fallback;

const ImageComponent = ({
  src,
  alt,
  responsive,
  className,
  pictureClassName,
  ...props
}: ImageProps) => {
  const [imgState, setImgState] = useState("loading");

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImgState("success");
    img.onerror = () => setImgState("error");
    img.src = src || "";

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  const { fallbackSrc, fallbackElement, ...propsRest } = {
    fallbackSrc: undefined,
    fallbackElement: undefined,
    ...props,
  };

  return imgState === "success" ? (
    responsive ? (
      <picture className={pictureClassName} {...propsRest}>
        {Object.entries(responsive).map(([key, value]) => (
          <source
            key={key}
            media={`(min-width: ${tailwindTheme.screens[key as keyof typeof tailwindTheme.screens]})`}
            srcSet={value}
          />
        ))}
        <img
          src={src || ""}
          alt={alt || ""}
          className={cn("h-full w-full", className)}
          loading="lazy"
          {...propsRest}
        />
      </picture>
    ) : (
      <img src={src || ""} alt={alt || ""} className={className} loading="lazy" {...propsRest} />
    )
  ) : imgState === "loading" ? (
    // Tailwind skeleton
    <div className={cn(className, "animate-pulse rounded-md bg-grey-100")} {...propsRest} />
  ) : !src || imgState === "error" ? (
    (fallbackElement ?? (
      <img src={fallbackSrc || altImage} alt={alt || ""} className={className} {...propsRest} />
    ))
  ) : null;
};

export { ImageComponent as Image };
