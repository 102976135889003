import { useEffect } from "react";
import type { AnalyticsEvent } from "~types/app/analytics-session.types";

function pageview(url: string, trackingId: string) {
  if (!window.gtag || !window.gaEnabled) {
    console.warn(`Analytics Pageview not sent because gtag is not loaded`, [
      "config",
      trackingId,
      { page_path: url },
    ]);
    return;
  }
  console.info(`Analytics Pageview sent`, ["config", trackingId, { page_path: url }]);
  window.gtag("config", trackingId, {
    page_path: url,
  });
}

function event({ action, data }: { action: string; data: any }) {
  if (!window.gtag || !window.gaEnabled) {
    console.warn(`Analytics Event not sent because gtag is not loaded`, ["event", action, data]);
    return;
  }
  console.info(`Analytics Event sent`, ["event", action, data]);
  window.gtag("event", action, data);
}

export const analytics = { pageview, event };

/** Display a toast message on component mount. */
export function useAnalyticsEvent({
  pageLoadEvent,
}: {
  pageLoadEvent?: AnalyticsEvent | Array<AnalyticsEvent> | null | undefined;
}) {
  useEffect(() => {
    if (pageLoadEvent) {
      setTimeout(() => {
        if (Array.isArray(pageLoadEvent)) {
          pageLoadEvent.forEach((event) =>
            analytics.event({ action: event.action, data: event.data })
          );
          return;
        }
        analytics.event({ action: pageLoadEvent.action, data: pageLoadEvent.data });
      }, 0);
    }
  }, [pageLoadEvent]);
}
